import { ReactComponent as MyAriyanaLogo } from 'assets/brand/sample.svg';
import { ReactComponent as MyMoneyXLogo } from 'assets/brand/moneyx-sidebar.svg';
import * as MyApiAuthorizationConstants from 'framework/base/authorization/ApiAuthorizationConstants';
import MyAuthorizationProvider, {
  useAuth as myUseAuth
} from 'framework/base/authorization/AuthorizationProvider';
import myAuthService from 'framework/base/authorization/AuthorizeService';
import MyAuthRouteGuard from 'framework/base/authorization/AuthRouteGuard';
import MyLoginCallbackHandler from 'framework/base/authorization/LoginCallbackHandler';
import myNoHashQueryStringUtils from 'framework/base/authorization/noHashQueryStringUtils';
import { isUserManager } from 'framework/base/utils/AuthorizationUtils';
import { parseAccessToken } from 'framework/base/utils/Utils';

/* ****************** Instances ********************** */
export const AriyanaLogo = MyAriyanaLogo;
export const MoneyXLogo = MyMoneyXLogo;

/* ****************** Authorization Instances ********************** */
export const ApiAuthorizationConstants = MyApiAuthorizationConstants;
export const AuthorizationProvider = MyAuthorizationProvider;
export const useAuth = myUseAuth;
export const authService = myAuthService;
export const AuthRouteGuard = MyAuthRouteGuard;
export const LoginCallbackHandler = MyLoginCallbackHandler;
export const noHashQueryStringUtils = myNoHashQueryStringUtils;

/* ****************** Authorization spec ********************** */
export const getPostLoginCallbackRoute = (accessToken) => {
  if (
    !isUserManager(accessToken) ||
    +parseAccessToken(accessToken).exa > new Date().getTime()
  ) {
    return '/dashboard/user-profile';
  }
  return '/dashboard/purchase-type';
};

/* ****************** theme spec ********************** */
export const componentSizesExtraVars = {
  common: {},
  desktop: {},
  laptop: {},
  tablet: {},
  mobile: {}
};

/* ****************** styles spec ********************** */
export const sidebarDrawerPaperStyles = (MuiStyled, MuiDrawer) =>
  MuiStyled(MuiDrawer)`
    flex-basis: ${(props) =>
      props.open
        ? props.theme.componentSizes.sidebar.opened.width
        : props.theme.componentSizes.sidebar.closed.width};
    .MuiDrawer-paper {
      width: ${(props) =>
        props.open
          ? props.theme.componentSizes.sidebar.opened.width
          : props.theme.componentSizes.sidebar.closed.width};
    }

    & .MuiDrawer-paper {
      transition: ${(props) =>
        props.theme.transitions.create('width', {
          easing: props.theme.transitions.easing.sharp,
          duration: props.open
            ? props.theme.transitions.duration.leavingScreen
            : props.theme.transitions.duration.enteringScreen
        })};
    }
  `;
export const sidebarTheme = 'dark';
export const idSidebarClosable = true;

/* ****************** environment spec ********************** */
export const imageAddressPrefix = '/';
export const openNewWindow = window.open;
export const getQueryStringsFromLocation = () =>
  window.location.search.substring(1);

export const showSettingsInNavbar = false;
export const showSwitchButtonInNavbar = true;
export const showSwitchOptionInNavbar = true;
export const getWindowLocationPathname = () => window.location.pathname;
export const showCreditInNavbar = false;
