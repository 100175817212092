import { createSlice } from '@reduxjs/toolkit';

export const systemSlice = createSlice({
  name: 'systemSlice',
  initialState: {
    errors: [],
    noVersionError: false,
    provideAuthorizationConfig: false,
    doBootstrap: false,
    doGetUserCurrencies: false,
    NewAppVersion: false,
    isWebsocketConnected: false,
    webSocketData: null,
    whatsapp: {
      isLogin: false,
      isLoading: true
    }
  },
  reducers: {
    setErrors: (state, action) => {
      state.errors = [].concat(action.payload);
    },
    setNoVersionError: (state, action) => {
      state.noVersionError = action.payload;
    },
    setProvideAuthorizationConfig: (state, action) => {
      state.provideAuthorizationConfig = action.payload;
    },
    setDoBootstrap: (state, action) => {
      state.doBootstrap = action.payload;
    },
    updateUserCurrencies: (state, action) => {
      state.doGetUserCurrencies = action.payload;
    },
    setWhatsappLoginState: (state, action) => {
      state.whatsapp.isLogin = action.payload;
    },
    setWhatsappLoadingState: (state, action) => {
      state.whatsapp.isLoading = action.payload;
    },
    setConnectionStatus: (state, action) => {
      state.isWebsocketConnected = action.payload;
    },
    setwebSocketData: (state, action) => {
      state.webSocketData = action.payload;
    }
  }
});

export const {
  setErrors,
  setNoVersionError,
  setProvideAuthorizationConfig,
  setDoBootstrap,
  updateUserCurrencies,
  setNewAppVersion,
  setWhatsappLoginState,
  setWhatsappLoadingState,
  setConnectionStatus,
  setwebSocketData
} = systemSlice.actions;
export default systemSlice.reducer;
