import { authService } from 'spec';

// Avoid using arrow functions here because of problems in functional context. (https://stackoverflow.com/questions/12995153/apply-not-working-as-expected)
function defaultCheckAccess(claimNames) {
  return this.claims && claimNames.some((cn) => !!+this.claims[cn]);
}

function managerCheckAccess(claimNames) {
  return (
    this.claims &&
    new Set([this.claims[claimNames[0]], this.claims[claimNames[1]]]).size === 1
  );
}

function departmentListAccess(claimNames) {
  return (
    this.claims &&
    new Set([this.claims[claimNames[0]], this.claims[claimNames[1]]]).size ===
      1 &&
    !this.claims[claimNames[2]]
  );
}

export async function GetAuthObject() {
  const claims = await authService.getClaims();
  return {
    AddEmployee: defaultCheckAccess.call(
      {
        claims
      },
      ['emp.cou']
    ),
    EmployeeList: defaultCheckAccess.call(
      {
        claims
      },
      ['emp.l']
    ),
    AddOrEditEmployee: defaultCheckAccess.call(
      {
        claims
      },
      ['emp.cou']
    ),
    DeleteEmployee: defaultCheckAccess.call(
      {
        claims
      },
      ['emp.d']
    ),
    UpdateEmployeeProfile: defaultCheckAccess.call(
      {
        claims
      },
      ['emp.cou']
    ),
    UpdateUserProfile: defaultCheckAccess.call(
      {
        claims
      },
      ['pr.u']
    ),
    VersionTypeApplication: defaultCheckAccess.call(
      {
        claims
      },
      ['vta']
    ),
    ExpirationApplication: defaultCheckAccess.call(
      {
        claims
      },
      ['exa']
    ),
    WhatsappAccess: defaultCheckAccess.call({ claims }, ['w.a']),
    isManager: managerCheckAccess.call({ claims }, ['aid', 'sub']),
    DepartmentListAccess: departmentListAccess.call({ claims }, [
      'aid',
      'sub',
      'root.id'
    ])
  };
}

export default GetAuthObject;
