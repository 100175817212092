// eslint-disable-next-line import/no-cycle
import { round } from '../utils/Utils';

export function formatMoneyAmount(amount, decimalPlaces = 0) {
  const fixeDecimalPlaces = !decimalPlaces ? 0 : decimalPlaces;
  if (decimalPlaces < 0) {
    const roundedAmount = round(amount, -decimalPlaces);
    const formattedNumber = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 0
    }).format(+roundedAmount);
    return formattedNumber !== 'NaN' ? formattedNumber : null;
  }
  const roundedNumber = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: fixeDecimalPlaces
  }).format(+amount);
  return roundedNumber !== 'NaN' ? roundedNumber : null;
}
export default { formatMoneyAmount };
